import React from 'react'
import {
  Row,
  Col,
  Container,
  FancyShow
} from '@kogk/common'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { Link } from '@kogk/gatsby-theme-base'

import styles from './StaffSection.module.scss'

export const query = graphql `
  query {
    allPrismicStarfsmadur {
      nodes {
        ...fragmentPrismicStarfsmadur
      }
    }
  }  
`

export default () => {
  const data = useStaticQuery(query)
  const staffData = data.allPrismicStarfsmadur.nodes

  return (
    <Container className='pt-4 pt-md-4 mt-md-3'>
      <Row>
        {staffData && staffData.map((item, key) => {
          return (
            <Col className='col-6 col-sm-4 col-md-3 px-1' key={key}>
              <StaffmemberItem
                email={item.data.email.text}
                image={item.data.image}
                name={item.data.name.text}
                title={item.data.title.text}
              />
            </Col>
          )
        })}
      </Row>
    </Container>
  )
}

const StaffmemberItem = ({
  email,
  image,
  name,
  title
}) => {
  const emailLink = `mailto:${email}`

  return (
    <FancyShow>
      <div className='w-100 pb-3'>
        {image && <Img className='mb-2' fluid={image.fluid} />}
        <h3 className='mb-0 blue'>{name}</h3>
        <h4 className='mb-0 blue'>{title}</h4>
        <Link className={styles.link} to={emailLink}>
          {email}
        </Link>
      </div>
    </FancyShow>
  )
}